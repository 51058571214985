import React from "react";
import { PageProps } from "gatsby";
import { Layout } from "../layouts/layout";
import { Container } from "../layouts/container";
import { SEO } from "../seo";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";
import { formatPrice } from "../../api/shared/utils";
import { GatsbyShop } from "../utils/shop";

export default ({
  pageContext: { shop, root },
}: PageProps<{}, { shop: GatsbyShop; root: string }>) => {
  return (
    <Layout shop={shop} root={root}>
      <SEO title={`Conditions de livraison | ${shop.name}`} />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{
            top: "4.625rem",
          }}
        >
          <Grid xs={12} sm={10}>
            <Button
              color="primary"
              href={`${root}/`}
              startIcon={<NavigateBefore />}
              sx={{ mb: 1, textTransform: "capitalize" }}
            >
              Retourner à la boutique
            </Button>
            <Typography sx={{ mt: 3, mb: 2 }} variant="h1">
              Conditions de livraison
            </Typography>
            <Typography sx={{ mt: 3, mb: 2 }} variant="h2">
              Frais de livraison
            </Typography>
            <TableContainer sx={{ my: 1 }} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Zone</TableCell>
                    <TableCell align="right">Bouteilles</TableCell>
                    <TableCell align="right">Prix</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>A la propriété</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">Gratuit</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Au salon</TableCell>
                    <TableCell align="right">-</TableCell>
                    <TableCell align="right">Gratuit</TableCell>
                  </TableRow>
                  {shop.shipping.home.zones.map(({ name, prices }) =>
                    prices.map(({ price, max, min }, index) => (
                      <TableRow key={name}>
                        {index === 0 && (
                          <TableCell rowSpan={prices.length}>{name}</TableCell>
                        )}
                        <TableCell align="right">
                          {min}
                          {max > 1000 ? "+" : ` à ${max}`}
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(price)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
